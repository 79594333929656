<template>
  <div>
    <quote-status-bar
      :item="order"
      :quote-status-bar="quoteStatusBar"
    />
    <router-view />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'CreateView',
  components: { QuoteStatusBar },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  setup() {
    const { MODULE_NAME, quoteStatusBar } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
    }
  },
}
</script>
